import { ReactElement, Fragment, ReactNode, useMemo } from 'react';

import { Box } from '@breathelife/mui';
import { Permission } from '@breathelife/types';
import { ErrorBoundary } from '@breathelife/ui-components';

import styled from '../../Styles/themed-styled-components';
import { GenericError } from '../../Components/Error/GenericError';
import { HelmetContainer } from '../../Components/HelmetContainer';
import { UserMenu } from '../../Components/Menu/UserMenu';
import { HEADER_HEIGHT } from '../../Models/Layout';
import { PageTabs, Tabs } from './PageTabs';
import { useTranslation } from 'react-i18next';

import { NotificationCentreDrawer } from '../../Pages/Home/NotificationCentre/NotificationCentreDrawer';
import { useCarrierContext, useSelector } from '../../Hooks';
import { userHasPermission } from '../../Helpers/user';
import { NotificationCentreIcon } from '../../Pages/Home/NotificationCentre/NotificationCentreIcon';

type PageLayoutProps = {
  children: ReactNode;
  tabs: Tabs[];
  pageTitle: string;
};

const StyledPageHeaderContainer = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

const RightMenuContainer = styled(Box)`
  display: flex;
  margin-bottom: 16px;
`;

export function LeadsPageLayout(props: PageLayoutProps): ReactElement {
  const { pageTitle, tabs = false } = props;
  const { features } = useCarrierContext();
  const { t } = useTranslation();

  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);

  const isNotificationCentreEnabled = useMemo(
    () => features.notificationCentre.enabled && userHasPermission(userPermissions, Permission.NotificationRead),
    [userPermissions, features.notificationCentre.enabled],
  );

  return (
    <Fragment>
      <HelmetContainer text={pageTitle} />
      <Box py={2} position='relative' width='100%' height='100vh' display='flex' flexDirection='column'>
        <StyledPageHeaderContainer
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          height={HEADER_HEIGHT}
          role='navigation'
          aria-label={t('leadsListTable.headerAriaLabel')}
        >
          {tabs && tabs.length !== 0 && <PageTabs tabs={tabs} defaultTab={tabs[0].to} />}
          <RightMenuContainer>
            {isNotificationCentreEnabled && <NotificationCentreIcon />}
            <UserMenu variant='default' showCarret showUserName />
          </RightMenuContainer>
        </StyledPageHeaderContainer>

        <Box flexGrow={1} minHeight={0} role='main'>
          <ErrorBoundary renderErrorComponent={() => <GenericError />}>{props.children}</ErrorBoundary>
        </Box>
      </Box>

      {isNotificationCentreEnabled && <NotificationCentreDrawer />}
    </Fragment>
  );
}
