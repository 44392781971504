import { ReactElement, useCallback, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import { IconButton } from '@breathelife/mui';
import { IconName, Permission } from '@breathelife/types';
import { Icon, ProducerNavigation, ProducerNavigationItemData } from '@breathelife/ui-components';

import { useTheme } from '../../Styles/themed-styled-components';
import { UserMenu } from '../../Components/Menu/UserMenu';
import { useCarrierContext, useSelector } from '../../Hooks';
import { ApplicationSupportHeader } from '../../Pages/Admin/ApplicationSupport/ApplicationSupportHeader/ApplicationSupportHeader';

import {
  PageLayoutGridWrapper,
  Sidebar,
  PageContent,
  PageHeader,
  LogoWrapper,
  Logo,
  ContentWrapper,
  BackButtonWrapper,
} from './styles';

import Urls from '../../Navigation/Urls';
import { userHasPermission } from '../../Helpers/user';
import { NotificationCentreDrawer } from '../../Pages/Home/NotificationCentre/NotificationCentreDrawer';
import { NotificationCentreIcon } from '../../Pages/Home/NotificationCentre/NotificationCentreIcon';

export type PageLayoutTab = {
  ['data-testid']: string;
  label: string;
  iconName?: IconName;
  to?: string;
  basePathForRelatedRoutes?: string;
  permissions?: Permission[];
  showWhenNotAllowed?: boolean;
  children?: PageLayoutTab[];
};

type PageLayoutProps = {
  tabs: PageLayoutTab[];
};

const applicationSupportTabs = [
  'application-support-tab-overview',
  'application-support-tab-esignature',
  'application-support-tab-underwriting',
  'application-support-tab-associated-files',
];

const applicationSupportTabToPageMapper = {
  ['application-support-tab-overview']: 'overview',
  ['application-support-tab-esignature']: 'eSignature',
  ['application-support-tab-underwriting']: 'underwriting',
  ['application-support-tab-associated-files']: 'associatedFiles',
};

function isActiveTab(tab: PageLayoutTab, currentLocationPathname: string): boolean {
  if (tab.basePathForRelatedRoutes) {
    return currentLocationPathname.includes(tab.basePathForRelatedRoutes);
  }
  return tab.to ? currentLocationPathname === tab.to : false;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}): ReactElement => {
  return condition ? wrapper(children) : children;
};

export function PageLayout({ tabs }: PageLayoutProps): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const { carrierInfo, features } = useCarrierContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);

  const selectedItemKey: string | undefined = useMemo(() => {
    let matchingTab: PageLayoutTab | undefined;
    tabs.some((tab) => {
      if (isActiveTab(tab, location.pathname)) {
        matchingTab = tab;
        return true;
      }
      if (tab.children) {
        let inChild = false;
        tab.children.some((childTab) => {
          if (isActiveTab(childTab, location.pathname)) {
            matchingTab = childTab;
            inChild = true;
            return true;
          }
          return false;
        });
        return inChild;
      }
      return false;
    });
    return matchingTab?.['data-testid'];
  }, [location.pathname, tabs]);

  const makeDataFromTab = useCallback(
    (tab: PageLayoutTab): ProducerNavigationItemData => {
      return {
        identifier: tab['data-testid'],
        text: tab.label,
        onClick: () => {
          if (tab.to) {
            navigate(tab.to);
          }
        },
        icon: tab.iconName
          ? {
              name: tab.iconName,
            }
          : undefined,
        permissions: tab.permissions ?? [],
        children: tab.children?.map(makeDataFromTab),
      };
    },
    [navigate],
  );

  const isNotificationCentreEnabled = useMemo(
    () => features.notificationCentre.enabled && userHasPermission(userPermissions, Permission.NotificationRead),
    [userPermissions, features.notificationCentre.enabled],
  );

  const data = useMemo(() => {
    return tabs.map(makeDataFromTab);
  }, [tabs, makeDataFromTab]);

  const isApplicationSupportPage = selectedItemKey ? applicationSupportTabs.includes(selectedItemKey) : false;

  return (
    <Fragment>
      <PageLayoutGridWrapper>
        <Sidebar>
          <LogoWrapper>
            <Logo
              src={carrierInfo.logo}
              radius={0}
              alt={`${carrierInfo.companyName} ${t('sidebar.logo')}`}
              isProfile={false}
            />
          </LogoWrapper>
          {isApplicationSupportPage && (
            <BackButtonWrapper>
              <IconButton
                onClick={() => {
                  navigate(Urls.applicationSupportApplications.fullPath);
                }}
                size='medium'
              >
                <Icon name={IconName.backArrow} color={{ primary: theme.colors.primary.default }} size={'30px'} />
              </IconButton>
            </BackButtonWrapper>
          )}
          <ProducerNavigation
            automaticallyOpenParentOfSelected
            data={data}
            applicablePermissions={userPermissions}
            selectedItemKey={selectedItemKey}
          />
        </Sidebar>
        <PageContent>
          <PageHeader>
            {isNotificationCentreEnabled && <NotificationCentreIcon />}
            <UserMenu variant='default' showCarret showUserName />
          </PageHeader>
          <ConditionalWrapper
            condition={isApplicationSupportPage}
            wrapper={(children) => (
              <ApplicationSupportHeader
                applicationSupportPage={applicationSupportTabToPageMapper[selectedItemKey || '']}
              >
                {children}
              </ApplicationSupportHeader>
            )}
          >
            <ContentWrapper>
              <Outlet />
            </ContentWrapper>
          </ConditionalWrapper>
        </PageContent>
      </PageLayoutGridWrapper>
      {isNotificationCentreEnabled && <NotificationCentreDrawer />}
    </Fragment>
  );
}
